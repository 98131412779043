<template>
  <form  @submit.prevent="createLogin" v-if="!isProcessed">
    <div class="_dashboard_content" v-if="tokenData">
        <div class="_dashboard_content_header">
            <div class="_dashboard__header_flex text-center">
                <h4>
                    <i class="bi bi-lock-fill"></i>
                    Retrieve your password
                </h4>
                <div class="mt-1 mb-1">
                    Enter your email, to retrieve your password
                </div>
            </div>
        </div>

        <div class="_dashboard_content_body">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="form-group">
                        <label :class="fv.email.msg?'text-danger':''" for="email">
                            Email
                            <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
                        </label>
                        <input type="text" placeholder="Enter email"
                               required v-model.trim="fv.email.val"
                               @keyup="validate.validateFormData(fv,'email',true)"
                               class="form-control with-light" id="email">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-12">
                    <div class="form-group">
                        <label v-if="fv.captcha.msg" class="form-label text-danger" >
                            {{fv.captcha.msg}}
                        </label>
                        <base-captcha :key="captcha" :captchaVal="captcha" ></base-captcha>
                        <input  required v-model="fv.captcha.val"  min="7" max="7"
                                @keyup="validate.validateFormData(fv,'captcha',true)"
                                type="text" placeholder="Enter the text above" class="form-control with-light">
                    </div>
                </div>

                <div class="col-12" v-if="fv.reqFeedback.status">

                    <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                </div>


                <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                    <button type="submit" class="btn btn-save col-12">Sign into your account</button>
                </div>
                <base-spinner class="ml-3" v-else></base-spinner>

            </div>
        </div>

    </div>
    <base-card-response  v-else>

        <template #header></template>
        <template #default>
            <p>
                {{reqError}}
            </p>
        </template>

    </base-card-response>
  </form>

  <base-card-response  cardType="success" v-if="isProcessed">

      <template #header>Your request has been processed. </template>
      <template #default>
          <p>
              If your email is valid you will recieve an email on how to reset your password.
          </p>
          <p><router-link to="/login"> <u style="color:#fff"> Go back to login </u></router-link></p>
      </template>

  </base-card-response>
</template>


<script>
import { ref,reactive,inject } from 'vue'
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthSignUp',
  setup() {

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');

    // initiate token, csrf_token and captcha variables with blank values
    let token = "";
    let csrf_token = "";
    const captcha = ref("");

    const tokenData  = ref(false);
    const reqError  = ref("Loading login form....");



    // when the vue module is created, get the token value, using the try catch

            // use axios to get the data
            axios.get(coreUrl.backendUrl+'get_csrf_token',{withCredentials: true}).then((response) =>{
                // return the full token to the token variable
                token = response.data.data;
                tokenData.value = true;

                // make the first 43 characters, the csrf token
                csrf_token = token.substring(0,43)

                // make the last 5 characters, the captcha
                captcha.value = token.substring(43,48)


            }).catch((error)=>{
                // if an error occours, do this
                tokenData.value = false;
                const errorMsg = "Login form can't be loaded at this time. Try again";
                reqError.value = error.response.data.message.req_msg?errorMsg:errorMsg;
            })




     // create variables for the values to be submitted
    const fv = reactive({
        email : {status:false, val:"", msg:false, validate:["required","email"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        captcha : {status:false, val:"", msg:false, validate:["required","alphaNumeric"]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isProcessed = ref(false);

    // create the account using async
    async function createLogin(){

        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();

        formData.append("fv_email", fv.email.val);
        formData.append("fv_host_root_url", coreUrl.jobsUrl);
        formData.append("fv_csrf_token",csrf_token+fv.captcha.val);
        formData.append("fv_account_type","individual");

        // post the data using axios
        try{

            const response = await axios.post(
                coreUrl.backendUrl+'create_forgot_password_reset',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data

            isProcessed.value = response.data.status;

            fv.reqFeedback.status = false;

            fv.showSubmit.val = true;



        }catch(error){
            // redo the captcha sequence
            token =  error.response.data.data?error.response.data.data:"bad";
            csrf_token =  error.response.data.data?token.substring(0,43):"bad";
            captcha.value =  error.response.data.data?token.substring(43,48):"bad";

            // load the error to the appropriate field
            const errorMessages = error.response.data.message;

            for(const key in errorMessages){
                if(fv[key]){
                    fv[key]["status"] = false;
                    fv[key]["msg"] = error.response.data.message[key];
                }
            }


            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = errorMessages["reqFeedback"]?errorMessages["reqFeedback"]:"Your request cannot be processed at this time. Kindly try again";
            fv.reqFeedback.val = "danger";
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // end load the error to the appropriate field

        }

     }



    return {
        fv,
        validate,
        tokenData,
        reqError,
        togglePasswordVisibility,
        createLogin,
        captcha,
        isProcessed
     }
  }
}
</script>
